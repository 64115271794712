import { SavedView } from '@hub-services/interfaces/saved-view';
import { createReducer, on } from '@ngrx/store';
import * as SavedViewsActions from './saved-views.actions';

export const featureKey = 'savedViews';

export interface HubSavedViewsState {
  items: SavedView[];
}

export const initialState: HubSavedViewsState = {
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(SavedViewsActions.saveNewView, (state, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items[newState.items.length] = action.savedView;

    return { ...newState };
  }),
  on(SavedViewsActions.updateView, (state, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    const idx = newState.items.findIndex(item => item.id === action.savedView.id);

    newState.items[idx] = { ...action.savedView };

    return { ...newState }
  }),
  on(SavedViewsActions.deleteView, (state, action) => ({
    items: state.items.filter(item => item.id !== action.id)
  })),
  on(SavedViewsActions.resetSavedViews, state => ({
    ...state,
    ...initialState,
  })),
);
