import { SavedView } from '@hub-services/interfaces/saved-view';
import { createAction, props } from '@ngrx/store';

export const saveNewView = createAction(
  '[Saved Views] Save new view',
  props<{ savedView: SavedView }>()
);

export const updateView = createAction(
  '[Saved Views] Update view',
  props<{ savedView: SavedView }>()
);

export const deleteView = createAction(
  '[Saved Views] Delete view',
  props<{ id: string }>()
);

export const resetSavedViews = createAction(
  '[Saved Views] Reset',
);
