import { Pipe, PipeTransform } from '@angular/core';
import { MediaType } from '../../uas/editing/media/media-type.enum';

@Pipe({
  name: 'getMediaTypeDisplayName'
})
export class GetMediaTypeDisplayNamePipe implements PipeTransform {

  transform(mediaType: MediaType): string {
    if (mediaType === MediaType.MARKETING_GRAPHIC) {
      return 'marketing graphic';
    } else if (mediaType === MediaType.VIRTUAL_TOUR) {
      return 'virtual tour';
    } else {
      return mediaType;
    }
  }
}
