import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService, User } from '@hub-services/user.service';
import { Inject, Injectable } from '@angular/core';

import { RoleType } from '../admin/role-type.enum';
import { WINDOW } from '@hub-shared/window.providers';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard  {

  private whitelistedRoles = [RoleType.ADMIN];

  constructor(
    private userService: UserService,
    @Inject(WINDOW) private window: Window,
  ) { }

  canActivate(): Observable<boolean> {
    return this.userService.getUser().pipe(
      map((response: any) => {
        return this.checkPermisions(response);
      }),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

  private checkPermisions(user: User): boolean {

    if (this.whitelistedRoles.includes(user.role)) {
      return true;
    }

    if(user && user.role !== RoleType.OBSERVER) {
      this.window.location.href = '/upas/view';
      return false;
    }

    this.window.location.href = '/login';
    return false;
  }
}
