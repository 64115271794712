import { filter, tap } from 'rxjs/operators';
import { SessionsService } from '@hub-services/sessions.service';
import { EditingDataService } from '@hub-services/editing-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Store } from '@ngrx/store';
import { setSessionData } from '@hub-store/session/session.actions';
import { environment } from '@hub-env/environment';
import { VisualStyle, SnackbarComponent } from '@atpco/hub-ui';
import { Observable } from 'rxjs';
import { getAppSnackbar } from '@hub-store/messages/messages.selectors';

@Component({
  selector: 'app-root',
  template: `
    <rh-ui-icons></rh-ui-icons>
    <router-outlet></router-outlet>
    <ng-container *ngIf="sub$ | async"></ng-container>
    <rh-ui-snackbar #snackbarRef [colorInput]="snackbarConfig?.color" [messageInput]="snackbarConfig?.message" [styleInput]="snackbarConfig?.style"
    [actionInput]="snackbarConfig?.action" [durationInput]="5000"></rh-ui-snackbar>

  `,
  host: {
    class: `environment--${environment.name}`
  },
})
export class AppComponent implements OnInit {
  @ViewChild('snackbarRef', { read: SnackbarComponent }) snackbarRef: SnackbarComponent;
  snackbarConfig: { style: VisualStyle, message: string, action: string, color: string; };
  sub$: Observable<any>;
  constructor(
    private router: Router,
    private sessionsService: SessionsService,
    private editingDataService: EditingDataService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private store: Store,
  ) {
    angulartics2GoogleAnalytics.startTracking();
    this.sessionsService.getSession(false).subscribe((session: any) => {
      this.store.dispatch(setSessionData({ data: session }))
      angulartics2GoogleAnalytics.setUserProperties({
        dimension1: session?.included?.partners ? session?.included?.partners[0]?.name : 'undefined',
        dimension2: session?.included?.users ? session?.included?.users[0]?.email : 'undefined',
      });
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized))
      .subscribe((e: any) => {
        const regEx = /(\/view)/;
        const regExUaEditing = /(edit)/;
        const path = e.urlAfterRedirects;
        localStorage.setItem('rhLastPath', localStorage.getItem('rhCurrentPath') || '');
        localStorage.setItem('rhCurrentPath', path);
        if (regEx.test(path)) {
          localStorage.setItem('rhLastSummaryPath', path);
        }
        if (!regExUaEditing.test(path)) {
          this.editingDataService.clearData();
        }
      });
    this.sub$ = this.store.select(getAppSnackbar)
      .pipe(
        filter(config => !!config.message),
        tap((config: { style: VisualStyle, message: string, action: string, color: string; }) => {
          this.snackbarConfig = config;
          this.snackbarRef.displaySnackbar();
        })
      )
  }
}
